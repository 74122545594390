<template>
  <div :class="{ 'is-busy': isDeleting }">
    <div class="d-flex align-items-center">
      <div
        @click="$emit('sessionEditorItemSelect')"
        class="d-flex align-items-center flex-grow-1 me-3"
      >
        <div
          @click.stop
          @dblclick.stop="toggleSuperset"
          v-if="!isSection"
          class="me-3"
        >
          <ExerciseBadge :exercise="exercise" :exercises="session.exercises"></ExerciseBadge>
        </div>
        <div class="flex-grow-1">
          <span
            :class="{ 'fw-bold': isSection }"
            class="me-2 lh-sm"
          >
            {{ exercise.name }}
            <Tooltip :content="'Linked to a metric'">
              <fa-icon v-if="athleteId && hasMetric" :icon="['fas', 'link']"></fa-icon>
            </Tooltip>
          </span>

          <ExerciseStatus
            v-if="itemStatus !== null"
            :isCompleted="itemStatus"
          ></ExerciseStatus>

          <TextView
            v-if="exercise.description"
            :text="exercise.description"
            class="smaller text-muted"
          ></TextView>

          <ExerciseSetRep :sets="$options.filters.exerciseSetParsed(exercise)"></ExerciseSetRep>

          <TextView
            v-if="itemNote && itemNote !== ''"
            :text="itemNote"
            :useQuote="true"
            class="mt-1 smaller"
          ></TextView>
        </div>
      </div>

      <IconButton
        @click="showConfirmModal = true"
        :icon="'trash-alt'"
        :isLoading="isDeleting"
        :disabled="isDeleting"
        class="btn btn-sm btn-danger ms-2"
      ></IconButton>
    </div>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteItem"
          @close="showConfirmModal = false"
          v-if="showConfirmModal"
          v-model="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>
  </div>
</template>

<script>
export default {
  name: 'SessionEditorItem',
  components: {
    Tooltip: () => import('@/components/Tooltip'),
    ExerciseBadge: () => import('@/components/ExerciseBadge'),
    ExerciseStatus: () => import('@/components/ExerciseStatus'),
    ExerciseSetRep: () => import('@/components/ExerciseSetRep'),
    TextView: () => import('@/components/TextView'),
    IconButton: () => import('@/components/button/IconButton'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  props: {
    session: {
      type: Object,
      required: true,
    },
    exercise: {
      type: Object,
      required: true,
    },
    athleteId: {
      type: String,
      default: null,
    },
  },
  watch: {
    session() {
      this.isDeleting = false;
    },
  },
  computed: {
    isSection() {
      return this.exercise.type === 'section';
    },
    isExercise() {
      return this.exercise.type === 'exercise';
    },
    isCircuit() {
      return this.exercise.type === 'circuit';
    },
    hasMetric() {
      return (this.isExercise || this.isCircuit) && this.exercise.metricId;
    },
    itemStatus() {
      if (this.isSection) return null;
      const { exerciseStatuses } = this.session;
      if (exerciseStatuses && exerciseStatuses.length) {
        const { exerciseId } = this.exercise;
        const exerciseStatus = exerciseStatuses.find((status) => status.exerciseId === exerciseId);
        if (exerciseStatus && exerciseStatus.isCompleted !== null) {
          return !!exerciseStatus.isCompleted;
        }
        return null;
      }
      return null;
    },
    itemNote() {
      if (this.isSection) return null;
      const { exerciseStatuses } = this.session;
      if (exerciseStatuses && exerciseStatuses.length) {
        const { exerciseId } = this.exercise;
        const exerciseStatus = exerciseStatuses.find((status) => status.exerciseId === exerciseId);
        if (exerciseStatus && exerciseStatus.note !== null) {
          return exerciseStatus.note;
        }
        return null;
      }
      return null;
    },
  },
  methods: {
    toggleSuperset() {
      const { exerciseId, isSuperset } = this.exercise;
      this.$emit('sessionEditorItemSuperset', { exerciseId, isSuperset: !isSuperset });
    },
    deleteItem() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      this.$emit('sessionEditorItemDelete', this.exercise.exerciseId);
    },
  },
  data() {
    return {
      showConfirmModal: false,
      showNoteModal: false,
      showMetricModal: false,
      isDeleting: false,
    };
  },
};
</script>

<style lang="scss" scoped>
blockquote {
  margin-top: .75rem;
  margin-bottom: 0;
}
</style>
